<template>
  <div class="general-report">
    <v-navigation-drawer
        class="non-printable"
        app
        :width="navWidth"
        right
        temporary
        v-model="setting_dialog"
        @input="onNavInput"
    >
      <v-btn
          @click="closeSettings"
          depressed
      >Приховати налаштування
      </v-btn>

      <v-row>
        <v-col cols="12">
          <v-card tile elevation="0">
            <v-card-text class="pt-2">
             <v-row>
               <v-col>
                 <v-select filled v-model="filters.services"
                           color="grey"
                           :items="services"
                           label="Послуги"
                           multiple
                           hide-details/>
               </v-col>
               <v-col cols="12">
                 <Checker :value="filters.checker_id" @autocompleteChange="onCheckerChange"/>
               </v-col>
             </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-navigation-drawer>
    <div class="report-section" style="padding-top: 2px; flex-wrap: wrap;">
      <div class="rp-col-12 header-underline">
        <div class="rp-row rp-text-center" style="font-size: 1.1rem; font-weight: 500">
          {{ organization.full_name }} (Загальний звіт)
        </div>
      </div>
      <div class="rp-col-12"></div>
      <div class="rp-col-12 text-center mt-2">
        {{ print_data.organization_details_with_account }}
      </div>
      <div class="rp-col-12 text-left mt-8">
        Дата формування: {{ getCurrentDate() | formatDate }} {{ getPrintDateRange() }}
      </div>
    </div>
    <div class="empty-row" style="height: 20px"></div>
    <div class="report-section" v-if="report_data.rows">
      <div class="report-table" style="page-break-after: auto; page-break-before: avoid">
        <div class="report-table-row">
          <div class="report-table-col" style="flex: 0 0 200px">
            <span>Послуга</span>
          </div>
          <div class="report-table-col report-table-col-group" style="flex: 0 0 270px; display: flex">
            <div class="col-group">
              <div class="col-group-row">
                <div class="col-group-col rp-col-12">
                  <span>Сальдо на початок</span>
                </div>
              </div>
              <div class="col-group-row">
                <div class="col-group-col rp-col-4">
                  <span>Дт</span>
                </div>
                <div class="col-group-col rp-col-4">
                  <span>Кт</span>
                </div>
                <div class="col-group-col rp-col-4">
                  <span>Згорнуто</span>
                </div>
              </div>
            </div>
          </div>
          <div class="report-table-col" style="flex: 0 0 80px; display: flex">
            <span>Об’єм</span>
          </div>
          <div class="report-table-col" style="flex: 0 0 96px; display: flex">
            <span>Нараховано</span>
          </div>
          <div class="report-table-col" style="flex: 0 0 96px; display: flex">
            <span>Перерахунок</span>
          </div>
          <div class="report-table-col" style="flex: 0 0 86px; display: flex">
            <span>ПДВ</span>
          </div>
          <div class="report-table-col" style="flex: 0 0 96px; display: flex">
            <span>Всього</span>
          </div>
          <div class="report-table-col" style="flex: 0 0 96px; display: flex">
            <span>Оплачено</span>
          </div>
          <div class="report-table-col report-table-col-group" style="flex: 0 0 270px; display: flex">
            <div class="col-group">
              <div class="col-group-row">
                <div class="col-group-col rp-col-12">
                  <span>Сальдо на кінець</span>
                </div>
              </div>
              <div class="col-group-row">
                <div class="col-group-col rp-col-4">
                  <span>Дт</span>
                </div>
                <div class="col-group-col rp-col-4">
                  <span>Кт</span>
                </div>
                <div class="col-group-col rp-col-4">
                  <span>Згорнуто</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <template v-for="(group_row, group_idx) in report_data.rows">
          <div class="report-table-row report-table-group-header" :key="`row-group-${group_idx}`">
            <div class="report-table-col" style="flex: 0 0 200px;">
              <span style="justify-content: left; padding-left: 10px">{{ group_row.not_living ? 'Нежитлові приміщення' : 'Житлові приміщення' }}</span>
            </div>
            <div class="report-table-col report-table-col-group" style="flex: 0 0 270px; display: flex">
              <div class="col-group">
                <div class="col-group-row">
                  <div class="col-group-col rp-col-4">
                    <span>{{ group_row.start_balance_minus | formatNumber }}</span>
                  </div>
                  <div class="col-group-col rp-col-4">
                    <span>{{ group_row.start_balance_plus | formatNumber }}</span>
                  </div>
                  <div class="col-group-col rp-col-4">
                    <span>{{ group_row.start_balance | formatNumber }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="report-table-col" style="flex: 0 0 80px; display: flex">
              <span></span>
            </div>
            <div class="report-table-col" style="flex: 0 0 96px; display: flex">
              <span>{{ group_row.charge | formatNumber }}</span>
            </div>
            <div class="report-table-col" style="flex: 0 0 96px; display: flex">
              <span>{{ group_row.recalculation | formatNumber }}</span>
            </div>
            <div class="report-table-col" style="flex: 0 0 86px; display: flex">
              <span>{{ (group_row.tax + group_row.tax_in) | formatNumber }}</span>
            </div>
            <div class="report-table-col" style="flex: 0 0 96px; display: flex">
              <span>{{ (group_row.result) | formatNumber }}</span>
            </div>
            <div class="report-table-col" style="flex: 0 0 96px; display: flex">
              <span>{{ group_row.pay | formatNumber }}</span>
            </div>
            <div class="report-table-col report-table-col-group" style="flex: 0 0 270px; display: flex">
              <div class="col-group">
                <div class="col-group-row">
                  <div class="col-group-col rp-col-4">
                    <span>{{ group_row.end_balance_minus | formatNumber }}</span>
                  </div>
                  <div class="col-group-col rp-col-4">
                    <span>{{ group_row.end_balance_plus | formatNumber }}</span>
                  </div>
                  <div class="col-group-col rp-col-4">
                    <span>{{ group_row.end_balance | formatNumber }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="report-table-row" v-for="(row, row_idx) in group_row.items" :key="`row-${row_idx}`">
            <div class="report-table-col" style="flex: 0 0 200px;">
              <span style="justify-content: left; padding-left: 20px">{{ row.service_name }}</span>
            </div>
            <div class="report-table-col report-table-col-group" style="flex: 0 0 270px; display: flex">
              <div class="col-group">
                <div class="col-group-row">
                  <div class="col-group-col rp-col-4">
                    <span>{{ row.start_balance_minus | formatNumber }}</span>
                  </div>
                  <div class="col-group-col rp-col-4">
                    <span>{{ row.start_balance_plus | formatNumber }}</span>
                  </div>
                  <div class="col-group-col rp-col-4">
                    <span>{{ row.start_balance | formatNumber }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="report-table-col" style="flex: 0 0 80px; display: flex">
              <span>{{ row.square | formatNumber }}</span>
            </div>
            <div class="report-table-col" style="flex: 0 0 96px; display: flex">
              <span>{{ row.charge | formatNumber }}</span>
            </div>
            <div class="report-table-col" style="flex: 0 0 96px; display: flex">
              <span>{{ row.recalculation | formatNumber }}</span>
            </div>
            <div class="report-table-col" style="flex: 0 0 86px; display: flex">
              <span>{{( row.tax + row.tax_in) | formatNumber }}</span>
            </div>
            <div class="report-table-col" style="flex: 0 0 96px; display: flex">
              <span>{{ (row.result) | formatNumber }}</span>
            </div>
            <div class="report-table-col" style="flex: 0 0 96px; display: flex">
              <span>{{ row.pay | formatNumber }}</span>
            </div>
            <div class="report-table-col report-table-col-group" style="flex: 0 0 270px; display: flex">
              <div class="col-group">
                <div class="col-group-row">
                  <div class="col-group-col rp-col-4">
                    <span>{{ row.end_balance_minus | formatNumber }}</span>
                  </div>
                  <div class="col-group-col rp-col-4">
                    <span>{{ row.end_balance_plus | formatNumber }}</span>
                  </div>
                  <div class="col-group-col rp-col-4">
                    <span>{{ row.end_balance | formatNumber }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <div class="report-table-row" style="font-weight: 500;">
          <div class="report-table-col" style="flex: 0 0 200px;">
            <span style="justify-content: flex-end; text-transform: uppercase">Всього:</span>
          </div>
          <div class="report-table-col report-table-col-group" style="flex: 0 0 270px; display: flex">
            <div class="col-group">
              <div class="col-group-row">
                <div class="col-group-col rp-col-4">
                  <span>{{ getTotal('rows', 'start_balance_minus') | formatNumber }}</span>
                </div>
                <div class="col-group-col rp-col-4">
                  <span>{{ getTotal('rows', 'start_balance_plus') | formatNumber }}</span>
                </div>
                <div class="col-group-col rp-col-4">
                  <span>{{ getTotal('rows', 'start_balance') | formatNumber }}</span>
                </div>
              </div>
            </div>

          </div>
          <div class="report-table-col" style="flex: 0 0 80px; display: flex">
            <span></span>
          </div>
          <div class="report-table-col" style="flex: 0 0 96px; display: flex">
            <span>{{ getTotal('rows', 'charge') | formatNumber }}</span>
          </div>
          <div class="report-table-col" style="flex: 0 0 96px; display: flex">
            <span>{{ getTotal('rows', 'recalculation') | formatNumber }}</span>
          </div>
          <div class="report-table-col" style="flex: 0 0 86px; display: flex">
            <span>{{ getTotal('rows', 'privilege') | formatNumber }}</span>
          </div>
          <div class="report-table-col" style="flex: 0 0 96px; display: flex">
            <span>{{ (getTotal('rows', 'monetary_privilege') + getTotal('report_data.rows', 'monetary_subsidy')) | formatNumber }}</span>
          </div>
          <div class="report-table-col" style="flex: 0 0 96px; display: flex">
            <span>{{ getTotal('rows', 'pay') | formatNumber }}</span>
          </div>
          <div class="report-table-col report-table-col-group" style="flex: 0 0 270px; display: flex">
            <div class="col-group">
              <div class="col-group-row">
                <div class="col-group-col rp-col-4">
                  <span>{{ getTotal('rows', 'end_balance_minus') | formatNumber }}</span>
                </div>
                <div class="col-group-col rp-col-4">
                  <span>{{ getTotal('rows', 'end_balance_plus') | formatNumber }}</span>
                </div>
                <div class="col-group-col rp-col-4">
                  <span>{{ getTotal('rows', 'end_balance') | formatNumber }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="empty-row" style="height: 20px"></div>
    <div class="report-section" v-if="report_data.rows">
      <div class="rp-col-3" style="flex: 0 0 360px">
        <div class="report-table">
          <div class="report-table-row">
            <div class="report-table-col" style="flex: 0 0 240px">
              <span style="justify-content: flex-start">Показник</span>
            </div>
            <div class="report-table-col" style="flex: 0 0 100px">
              <span>Значення</span>
            </div>
          </div>
          <template v-for="(group_row, group_idx) in report_data.statistic">
            <div class="report-table-row report-table-group-header" :key="`stat-row-0-${group_idx}`">
              <div class="report-table-col" style="flex: 0 0 240px">
                <span style="justify-content: flex-start; padding-left: 10px">{{ group_row.not_living ? 'Нежитлові приміщення' : 'Житлові приміщення' }}</span>
              </div>
              <div class="report-table-col" style="flex: 0 0 100px">
                <span></span>
              </div>
            </div>
            <div class="report-table-row" :key="`stat-row-1-${group_idx}`">
              <div class="report-table-col" style="flex: 0 0 240px">
                <span style="justify-content: flex-start; padding-left: 20px;">К-сть будинків</span>
              </div>
              <div class="report-table-col" style="flex: 0 0 100px">
                <span>{{  group_row.buildings }}</span>
              </div>
            </div>
            <div class="report-table-row" :key="`stat-row-2-${group_idx}`">
              <div class="report-table-col" style="flex: 0 0 240px">
                <span style="justify-content: flex-start; padding-left: 20px;">К-сть абонентів</span>
              </div>
              <div class="report-table-col" style="flex: 0 0 100px">
                <span>{{ group_row.flats }}</span>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="rp-col-4" style="flex: 0 0 420px">
        <div class="report-table">
          <div class="report-table-row">
            <div class="report-table-col" style="flex: 0 0 240px">
              <span>Банк</span>
            </div>
            <div class="report-table-col" style="flex: 0 0 100px">
              <span>Сума</span>
            </div>
            <div class="report-table-col" style="flex: 0 0 60px">
              <span>%</span>
            </div>
          </div>
          <template v-for="(group_row, group_idx) in report_data.banks">
            <div class="report-table-row report-table-group-header" :key="`bank-row-group-${group_idx}`">
              <div class="report-table-col" style="flex: 0 0 240px">
                <span style="justify-content: flex-start; padding-left: 10px">{{ group_row.not_living ? 'Нежитлові приміщення' : 'Житлові приміщення' }}</span>
              </div>
              <div class="report-table-col" style="flex: 0 0 100px">
                <span>{{ group_row.sum | formatNumber }}</span>
              </div>
              <div class="report-table-col" style="flex: 0 0 60px">
                <span>{{ group_row.percent | formatNumber }}</span>
              </div>
            </div>
            <div class="report-table-row" v-for="(bank_row, bank_idx) in group_row.items" :key="`bank-row-${bank_idx}`">
              <div class="report-table-col" style="flex: 0 0 240px">
                <span style="justify-content: flex-start; padding-left: 20px">{{ bank_row.name }}</span>
              </div>
              <div class="report-table-col" style="flex: 0 0 100px">
                <span>{{ bank_row.sum | formatNumber }}</span>
              </div>
              <div class="report-table-col" style="flex: 0 0 60px">
                <span>{{ bank_row.percent | formatNumber }}</span>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="rp-col-4">
        <div class="report-table">
          <div class="report-table-row">
            <div class="report-table-col" style="flex: 0 0 220px">
              <span>Послуга</span>
            </div>
            <div class="report-table-col" style="flex: 0 0 100px">
              <span>По нормативу</span>
            </div>
            <div class="report-table-col" style="flex: 0 0 100px">
              <span>По квартирному лічильнику</span>
            </div>
            <div class="report-table-col" style="flex: 0 0 100px">
              <span>По будинковому лічильнику</span>
            </div>
          </div>
          <template v-for="(group_row, group_idx) in report_data.volumes">
            <div class="report-table-row report-table-group-header" :key="`vol-row-group${group_idx}`">
              <div class="report-table-col" style="flex: 0 0 220px">
                <span style="justify-content: flex-start; padding-left: 10px">
                  {{ group_row.not_living ? 'Нежитлові приміщення' : 'Житлові приміщення' }}
                </span>
              </div>
              <div class="report-table-col" style="flex: 0 0 100px">
                <span>{{ group_row.volume_normative | formatNumber('0.0000') }}</span>
              </div>
              <div class="report-table-col" style="flex: 0 0 100px">
                <span>{{ group_row.volume_counter | formatNumber('0.0000')}}</span>
              </div>
              <div class="report-table-col" style="flex: 0 0 100px">
                <span>{{ group_row.volume_building_counter | formatNumber('0.0000') }}</span>
              </div>
            </div>
            <div class="report-table-row" v-for="(volume_row, volume_idx) in group_row.items"
                 :key="`vol-row-${volume_idx}`">
              <div class="report-table-col" style="flex: 0 0 220px">
                <span style="justify-content: flex-start; padding-left: 20px">{{ volume_row.service_name }}</span>
              </div>
              <div class="report-table-col" style="flex: 0 0 100px">
                <span>{{ volume_row.volume_normative | formatNumber('0.0000') }}</span>
              </div>
              <div class="report-table-col" style="flex: 0 0 100px">
                <span>{{ volume_row.volume_counter | formatNumber('0.0000') }}</span>
              </div>
              <div class="report-table-col" style="flex: 0 0 100px">
                <span>{{ volume_row.volume_building_counter | formatNumber('0.0000') }}</span>
              </div>
            </div>
          </template>

        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {formatDate} from "@/filters";
import reportAPI from "@/utils/axios/reports"
import {ALERT_SHOW} from "@/store/actions/alert";
import {mapGetters} from "vuex";
// import {groupBy} from 'lodash'

export default {
  name: "general_report_legacy",
  props: ['organization', 'print_data', 'global_settings', 'scale', 'date_start', 'date_end', 'generate', 'generate_xlsx', 'generate_email', 'email_address'],
  components: {
    Checker: () => import("@/components/autocomplite/Checker")
  },
  computed: {
    ...mapGetters({
      services: 'getServicesSelectWithoutAll'
    }),
    navWidth() {
      if (this.$vuetify.breakpoint.xs) {
        return '80%'
      }

      if (this.$vuetify.breakpoint.sm) {
        return '60%'
      }

      return '35%'
    }
  },
  data() {
    return {
      report_data: {},
      filters: {
        checker_id: null,
        services: []
      },
      setting_dialog: this.global_settings,
      current_date: this.getCurrentDate(),
      settings_watcher: null,
      generate_watcher: null,
      generate_xlsx_watcher: null,
      generate_email_watcher: null,
      local_response_people: this.print_data?.response_people ? this.print_data.response_people.slice() : [],
    }
  },
  methods: {
    onCheckerChange(payload) {
      this.filters.checker_id = (payload || {}).value || null
    },
    onNavInput(e) {
      if (!e) {
        this.$emit('closeSettings')
      }
    },
    formatDate,
    getTotal(table_name, col) {
      if (!this.report_data[table_name]) return 0
      return this.report_data[table_name].reduce((sum, item) => sum + item[col], 0)
    },
    getCurrentDate() {
      const date = new Date()
      const year = date.getUTCFullYear()
      const month = date.getUTCMonth() + 1 < 10 ? '0' + (date.getUTCMonth() + 1) : date.getUTCMonth() + 1
      const day = date.getUTCDate() < 10 ? '0' + date.getUTCDate() : date.getUTCDate()
      return `${year}-${month}-${day}`
    },
    getPrintDateRange() {
      if (this.date_start && this.date_end) {
        const formatted_date_start = this.formatDate(this.date_start, 'MMMM YYYY')
        const formatted_date_end = this.formatDate(this.date_end, 'MMMM YYYY')

        if (formatted_date_start === formatted_date_end) {
          return `. Період звіту: ${formatted_date_start}`
        } else {
          return `. Період звіту: з ${formatDate(this.date_start)} по ${formatDate(this.date_end)}`
        }
      }
    },
    closeSettings() {
      this.setting_dialog = false
      this.$emit('closeSettings')
    },
    watch_settings() {
      this.settings_watcher = this.$watch(
          'global_settings',
          {
            handler(payload) {
              this.setting_dialog = payload
            }
          }
      )
    },
    watch_generate() {
      this.settings_watcher = this.$watch(
          'generate',
          {
            handler(payload) {
              if (payload) {
                this.generate_report()
              }
            }
          }
      )
    },
    watch_generate_xlsx() {
      this.generate_xlsx_watcher = this.$watch(
          'generate_xlsx',
          {
            handler(payload) {
              if (payload) {
                this.generate_xlsx_file()
              }
            }
          }
      )
    },
    group_by(iterable, column) {
      const new_obj = []

      iterable.map(item => {
        const column_value = item[column]
        const find = new_obj.find(i => i[column] === column_value)

        if (!find) {
          const row = { [column]: column_value }
          Object.keys(item).filter(key => key !== column).map(key => row[key] = item[key])
          row['items'] = [Object.freeze(item)]
          new_obj.push(row)
        } else {
          Object.keys(item).filter(key => key !== column).map(key => {
            const item_value = item[key]
            if (typeof item_value === "number") {
              find[key] += item_value
            }
          })
          find['items'].push(Object.freeze(item))
        }
      })

      return new_obj
    },
    generate_xlsx_file() {
      if (this.date_start && this.date_end) {
        const payload = {date_start: this.date_start, date_end: this.date_end, filters: this.filters}
        reportAPI.general_report_xlsx(payload)
          .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a');
            const file_name = `general_report_${formatDate(this.date_start, 'MM_YYYY')}_${formatDate(this.date_end, 'MM_YYYY')}.xlsx`
            link.href = url;
            link.setAttribute('download', file_name); //or any other extension
            document.body.appendChild(link);
            link.click();
          })
          .catch(err => {
            const error = err.response.data.detail;
            this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
          })
          .finally(() => {
            this.$emit('generate_xlsx')
          })
      }
    },
    generate_email_send() {
      if (this.date_start && this.date_end) {
        this.$emit('email_loading_status', true)
        const payload = {date_start: this.date_start, date_end: this.date_end, filters: this.filters, email: this.email_address}
        reportAPI.general_report_legacy_email(payload)
            .then(response => response.data)
            .then(data => {
              this.$emit('email_loading_status', false)
              this.$store.dispatch(ALERT_SHOW, {color: data.status === 'OK' ? 'success' : 'error', text: data.text})
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$emit('email_loading_status', false)
              this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
            .finally(() => {
              this.$emit('generate_email')
            })
      }
    },
    generate_report() {
      if (this.date_start && this.date_end) {
        const payload = {date_start: this.date_start, date_end: this.date_end, filters: this.filters}
        reportAPI.general_report_legacy(payload)
            .then(response => response.data)
            .then(data => {
              const rows = this.group_by(data.rows, 'not_living')
              const banks =this.group_by(data.banks, 'not_living')
              const statistic = this.group_by(data.statistic, 'not_living')
              const volumes =  this.group_by(data.volumes, 'not_living')

              this.report_data = Object.assign(
                  {}, this.report_data, {rows: rows}, {banks: banks}, {statistic: statistic}, {volumes: volumes}
                  )
            })
            .catch(err => {
              const error = err.response.data.detail;
              this.$store.dispatch(ALERT_SHOW, {text: error, color: 'error lighten-1'})
            })
            .finally(() => {
              this.$emit('generate_report', !!this.report_data.rows)
            })
      }
    }
  },
  created() {
    this.watch_settings()
    this.watch_generate()
    this.watch_generate_xlsx()
    this.watch_generate_email()
  },
  beforeDestroy() {
    if (this.settings_watcher) {
      this.settings_watcher()
    }
    if (this.generate_watcher) {
      this.generate_watcher()
    }
    if (this.generate_xlsx_watcher) {
      this.generate_xlsx_watcher()
    }
    if (this.generate_email_watcher) {
      this.generate_email_watcher()
    }
  }
}
</script>

<style lang="scss">
$border-color: #b3b3b3;

@media print {
  .report-table-group-header {
    page-break-before: auto;
    page-break-after: auto;
    page-break-inside: avoid;
  }
  .report-table-group-content {
    page-break-before: auto;
    page-break-after: auto;
    page-break-inside: avoid;
  }

  //@page {
  //  size: A4 landscape;
  //}
}

.general-report {
  .report-table {
    width: 100%;
    text-align: center;

    .report-table-row:nth-child(1) {
      & > div {
        background-color: #f9f9f9;
        font-weight: 500;
      }
    }

    .report-table-group-header {
      & > div {
        background-color: #fafafa;
        font-weight: 500;
      }
    }

    .report-table-row {
      min-height: 26px;
      line-height: 1.3;

      &:nth-child(1) {
        .report-table-col-group {
          .col-group {
            .col-group-row:nth-child(1) {
              border-bottom: 1px solid #dde1e3;
            }
          }
        }
      }

      .report-table-col:last-child:not(.report-table-col-group) {
        border-right: 1px solid #dde1e3;
      }

      .report-table-col-group:last-child {
        .col-group-col:last-child {
          border-right: 1px solid #dde1e3;
        }

        .col-group-col:not(:last-child) {
          border-right: 1px solid #dde1e3;
        }
      }

      .report-table-col-group:not(:last-child) {
        .col-group-col:not(:last-child) {
          border-right: 1px solid #dde1e3;
        }
      }

      &:last-child {
        .report-table-col:not(.report-table-col-group) {
          border-bottom: 1px solid #dde1e3;
        }

        .report-table-col-group {
          border-bottom: 1px solid #dde1e3;
        }
      }
    }

  }

  .report-table-col-group {
    //.col-group {
    //  .col-group-row:nth-child(1) {
    //    border-right: 1px solid #dde1e3;
    //  }
    //}
    .col-group-row {
      flex: 1;
    }

    //&:nth-last-child(1) {
    //  .col-group-col:last-child {
    //    border-right: 1px solid #dde1e3;
    //  }
    //}
  }

  .report-table-col, .report-table-col-group {
    border-left: 1px solid #dde1e3;
    border-top: 1px solid #dde1e3;

    span {
      padding: 3px;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .report-table-group {
    font-size: 12px;
    width: 100%;

    .report-table-group-header {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      font-size: 12px;
      font-weight: 500;
      background-color: #f2f2f2;

    }

    .report-table-group-content {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      font-size: 12px;

      .report-table-col {
        &:nth-child(1) {
          padding-left: 30px !important;
          padding-right: 5px;
        }
      }
    }
  }

  .col-group {
    display: flex;
    flex-direction: column;
    width: 100%;

    .col-group-row {
      display: flex;

      &:nth-child(1) {
        //border-bottom: 1px solid $border-color;
        text-align: center;
        justify-content: center;
      }

      .report-table-col {
        //border-right: 1px solid $border-color;

        &:last-child {
          border-right: none;
        }
      }
    }
  }

}
</style>
